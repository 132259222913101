:root {
    --color-webtim-default: #333;
    --cookie-color-stroke: #999;
    --cookie-color-btns: #999;
    --cookie-color-text: #666;
}

*,
*:after,
*:before {
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    -webkit-font-smoothing:antialiased;
    font-smoothing:antialiased;
    text-rendering:optimizeLegibility;
}

/* Micro clearfix */
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}

html {
    font-size: 62.5%;
}

html,
body {
    font-style: normal;
}

body {
    font-size: 1.6rem;
}

img.alignleft {
    float: left;
}

img.alignright {
    float: right;
}

@media (max-width: 768px) {
    .webtim-copyright--shortcode--container {
        text-align: center;
    }
}

.webtim-copyright--shortcode--container .divider {
    margin-left: 16px;
    margin-right: 16px;
}

.webtim-copyright--shortcode--container .author__wrapper .copy-author-word {
    margin-right: 20px;
}

@media (max-width: 768px) {
    .webtim-copyright--shortcode--container .divider {
        display: block;
    }

    .webtim-copyright--shortcode--container .author__wrapper {
        display: block;
    }
}

.webtim-copyright--shortcode--container .webtim-logo--customer-page--svg__container > svg {
    max-width: 116px;
    width: 100%;
    max-height: 25px;
    height: 100%;
}

.webtim-copyright--shortcode--container .webtim-logo--customer-page--svg__container > svg path.svg-text {
    fill: #fff;
}

/** CF7 default styles **/
form.wpcf7-form .wpcf7-text,
form.wpcf7-form .wpcf7-select,
form.wpcf7-form textarea {
    box-sizing: border-box;
    padding: 18px 14px;
    font-size: 1.6rem;
    border: 1px solid var(--color-webtim-default);
}

form.wpcf7-form textarea {
    width: 100%;
}

form.wpcf7-form input,
form.wpcf7-form .wpcf7-text:focus,
form.wpcf7-form input[type="checkbox"]:focus,
form.wpcf7-form textarea:focus {
    outline-width: 0;
}

form.wpcf7-form input[type="checkbox"] {
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    padding: 8px;
    margin-top: 0;
    margin-right: 4px;
    margin-left: 0;
    background-color: #fff;
    border: 2px solid var(--color-webtim-default);
}

form.wpcf7-form input[type="checkbox"]:checked {
    border-color: var(--color-webtim-default);
}

form.wpcf7-form input[type="checkbox"]:checked::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: var(--color-webtim-default);
    transform: translate(-50%, -50%);
}

form.wpcf7-form input[type="submit"] {
    padding: 18px 94px;
    font-weight: bold;
    color: var(--color-webtim-default);
    background-color: #fff;
    border: 1px solid var(--color-webtim-default);
    transition: all .4s ease;
}

form.wpcf7-form input[type="submit"]:hover {
    color: #fff;
    background-color: var(--color-webtim-default);
}

.wpcf7-form-control .wpcf7-list-item label {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
}

div.webtim-contact-form--terms-text {
    font-size: 1.4rem;
    font-weight: normal;
}

div.webtim-contact-form--terms-text a {
    color: var(--color-webtim-default);
    text-decoration: underline transparent;
    transition: text-decoration-color .4s ease;
}

div.webtim-contact-form--terms-text a:hover,
div.webtim-contact-form--terms-text a:focus {
    text-decoration-color: var(--color-webtim-default);
}

div.webtim-contact-form--terms-text .no-margin {
    margin-bottom: 0;
}

div.webtim-contact--terms-text .marketing-other {
    color: var(--color-webtim-default);
}

/*** 404 style ***/
.webtim-404--main__wrapper {
    text-align: center;
}

.webtim-404--main__wrapper .webtim-404--main__content {
    box-sizing: border-box;
    max-width: 566px;
    width: 100%;
    padding-left: 14px;
    padding-right: 14px;
    margin: 60px auto 60px;
}

.webtim-404--main__wrapper .webtim-404--main__content header {
    margin-bottom: 14px;
}

.webtim-404--main__wrapper .webtim-404--main__content header img {
    max-width: 100%;
    width: 100%;
    margin-bottom: 28px;
}

.webtim-404--main__wrapper .webtim-404--main__content .webtim-404--title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2rem;
    line-height: 3rem;
}

.webtim-404--main__wrapper .webtim-404--main__content .webtim-404--content__wrapper p {
    margin-top: 0;
    margin-bottom: 0;
}

.webtim-404--main__wrapper .webtim-404--main__content .webtim-404--content__wrapper .search-form__wrapper {
    margin-top: 56px;
}

/*** Search Form ***/
form.webtim-search--form__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 16px;
    background-color: #fafafa;
    border: 1px solid #eaeaea;
}

form.webtim-search--form__container input[type="search"] {
    position: relative;
    width: 100%;
    background-color: transparent;
    border: none;
}

form.webtim-search--form__container input[type="search"]:focus {
    outline: none;
}

form.webtim-search--form__container button[type="submit"] {
    color: var(--color-webtim-default);
    background-color: transparent;
    border: none;
}

form.webtim-search--form__container button[type="submit"] svg {
    width: 21px;
    height: 21px;
}

/* Google reCAPTCHA v3 move badge to left side of screen */
.grecaptcha-badge {
    left: 0 !important;
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
}

.grecaptcha-badge:hover {
    width: 256px !important;
}