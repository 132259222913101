.webtim-flex__content {
    display: flex;
}

.webtim-flex-inline__content {
    display: inline-flex;
}

.webtim-flex__content.flex-wrap {
    flex-wrap: wrap;
}

.webtim-flex__content.horizontal-start,
.webtim-flex-inline__content.horizontal-start {
    justify-content: flex-start;
}
.webtim-flex__content.horizontal-center,
.webtim-flex-inline__content.horizontal-center {
    justify-content: center;
}
.webtim-flex__content.horizontal-end,
.webtim-flex-inline__content.horizontal-end {
    justify-content: flex-end;
}
.webtim-flex__content.horizontal-space,
.webtim-flex-inline__content.horizontal-space {
    justify-content: space-between;
}

.webtim-flex__content.vertical-start,
.webtim-flex-inline__content.vertical-start {
    align-items: flex-start;
}
.webtim-flex__content.vertical-center,
.webtim-flex-inline__content.vertical-center {
    align-items: center;
}
.webtim-flex__content.vertical-bottom,
.webtim-flex-inline__content.vertical-bottom {
    align-items: flex-end;
}

.webtim-flex__content.content-start,
.webtim-flex-inline__content.content-start {
    align-content: flex-start;
}
.webtim-flex__content.content-center,
.webtim-flex-inline__content.content-center {
    align-content: center;
}
.webtim-flex__content.content-bottom,
.webtim-flex-inline__content.content-bottom {
    align-content: flex-end;
}